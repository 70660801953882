import config from 'react-global-configuration'
const fragment = window.location.search;
const queryParams = fragment.split('?')[1]; 
const params = new URLSearchParams(queryParams);
const camp = params.get("camp");
const rcid = params.get("rcid");
console.log("camp and rcid",camp,rcid)

config.set({
    ga_tag: "",
    ga_debug: false,
    base:"https://xgamesv1.bngrenew.com/api",
    elastic_base_url:"https://serviceusagesv1.bngrenew.com/publish",
    domain : "btc",
    he : "/he",
    subscribe: '/subscribe',
    requestOTP: "/requestOTP",
    activate: "/activate",
    checkSub: "/checkSub",
    unsubscribe : '/unsubscribe',
    userPacks : '/userPacks',
    appConfig : '/config' , 
    subscribe:"/subscribe",
    sendOtp:"/pinsend",
    pinSubmit:"/pinsubmit",
    passToken:"/passToken",
    verifyPin:"/verifyPIN",
    headers: {
        'Reqfrom': 'web',
        'Username': 'web',
        'Servicefor':'dana_Id',
        'camp':camp ? camp : sessionStorage.getItem("camp") ? sessionStorage.getItem("camp") : "na",
        'rcid':rcid ? rcid : sessionStorage.getItem("rcid") ? sessionStorage.getItem("rcid") : "na",
    }
})


export default config